import api from './api'

export async function fetchCustomers(page, rowsPerPage) {
  try {
    return await api.get(`/contatos?page=${page}&size=${rowsPerPage}`)
  } catch (error) {
    console.error(error)
  }
}

export async function AllCustomers() {
  const response = await api.get('/contatos')
  return response?.data
}

export async function deleteCustomer(id: number) {
  try {
    return await api.delete(`/contatos/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function findCustomers(id: number) {
  try {
    const response = await api.get(`/contatos/${id}`)
    return response?.data
  } catch (error) {
    console.error(error)
  }
}

export async function fetchAcquisition() {
  const response = await api.get('/contatos/all-tipos-indicacao')
  return response?.data
}

export async function fetchGender() {
  const response = await api.get('/contatos/all-generos')
  return response?.data
}