import styled from 'styled-components'
import { colors } from '../../utils/colors'

export const FullPage = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px 3%;
`

export const TitlePage = styled.p`
  font-size: 44px;
  font-weight: 700;
  color: ${colors.main};
`

export const ContainerFieldset = styled.div`
  padding: 20px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.third};
  border-radius: 6px;
`
