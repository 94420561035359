import React, { useEffect, useState } from 'react'
import {
  Alert, Button,
  Card, IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import * as S from './styles'
import { useLocation, useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import { TablePaginationActions } from "../../components/Table/Pagination";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ConfirmationModal from "../../components/DialogModal/ConfirmationModal";
import { deleteServiceType, fetchServiceTypes } from '../../services/TipoDeServicoService';
import {enqueueSnackbar} from 'notistack';

function ServiceType() {
  const [idToDelete, setIdToDelete] = useState();
  const [rows, setRows] = useState([])
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)

  const params = useLocation();
  const navigate = useNavigate();

  //Pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const navigateNewServiceType = () => {
    navigate(`/servicos/new`);
  }

  const navigateToEdit = (id: number) => {
    navigate(`/servicos/edit/${id}`);
  }

  const handleServiceExclusion = async () => {
    await deleteServiceType(idToDelete)
    setIsOpenConfirmation(false)
    updateTable()
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const updateTable = async () => {
    const response = await fetchServiceTypes(page, rowsPerPage)
    if(response.data.content <= rowsPerPage * page && page > 0){
      setPage(page - 1)
    }
    setRows(response.data.content)
    setTotalRows(response.data.totalElements)

  }

  useEffect(() => {
    if (params.state?.inserted) {
      enqueueSnackbar('Serviço inserido com sucesso', {variant: "success"})
    }
    if (params.state?.edited) {
      enqueueSnackbar('Serviço editado com sucesso', {variant: "success"})
    }
  }, [])

  useEffect(() => {
    updateTable()
  }, [page, rowsPerPage])

  return (
    <S.FullPage>
      <S.TitlePage>Listagem serviços</S.TitlePage>
      <Button sx={{ width: 260, height: 46 }} variant="contained"
        onClick={navigateNewServiceType}>
        Registrar serviço
      </Button>

      <TableContainer component={Card} elevation={3}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '30%' }}>Nome</TableCell>
              <TableCell sx={{ width: '20%' }}>Valor Serviço</TableCell>
              <TableCell sx={{ width: '20%' }}>Descrição</TableCell>
              <TableCell sx={{ minWidth: '64px', padding: 0 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ width: '30%' }}>{row.nome}</TableCell>
                <TableCell sx={{ width: '20%' }}>R$ {row.valorBase
                  .toFixed(2)
                  .replace('.', ',')}</TableCell>
                <TableCell sx={{ width: '30%' }}>{row.descricao}</TableCell>
                <TableCell sx={{ minWidth: '64px', padding: 0 }}>
                  <IconButton
                    sx={{ padding: '4px' }}
                    onClick={() => navigateToEdit(row.id)}
                  >
                    <ModeEditOutlineOutlinedIcon />
                  </IconButton>
                  <IconButton
                    sx={{ padding: '4px' }}
                    onClick={() => {
                      setIdToDelete(row.id);
                      setIsOpenConfirmation(true);
                    }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={6}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* <Snackbar open={showAlert} autoHideDuration={6000} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }} onClose={handleCloseAlert}>
        <Alert
          variant="filled"
          severity={alertSeverity === "success" ? "success" : "error"}
          sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar> */}
      <ConfirmationModal
        title={"Atenção!"}
        message={"Deseja realmente excluir este serviço?"}
        open={isOpenConfirmation}
        onAgree={handleServiceExclusion}
        onDisagree={() => setIsOpenConfirmation(false)}
      />
    </S.FullPage>
  )
}

export default ServiceType