import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Card,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup'
import * as S from './styles'
import api from "../../../services/api";

import BackButton from '../../../components/BackButton'
import ComplementInput from '../../../components/ComplementInput'
import { InputMoney } from '../../../components/Inputs'
import { convertValue, getType } from '../../../utils/mask'
import { fetchCategory, findServiceType } from '../../../services/TipoDeServicoService';

const schemaAddService = yup.object().shape({
  name: yup.string().required('Informe o nome do serviço'),
  serviceValue: yup.string().test('verify_service_value', 'Informe o valor do serviço.', e => {
    return convertValue(e) > 0
  }),
  description: yup.string().required('Informe a descrição do serviço')
})

export default function FormServiceType() {
  const [selectedItem, setSelectedItem] = useState('');
  const navigate = useNavigate();
  const params = useParams();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schemaAddService)
  })

  const handleCreateService = async (data) => {
    const createServiceRequest = {
      nome: data.name,
      valorBase: convertValue(data.serviceValue),
      descricao: data.description
    }

    try {
      if (params?.id) {
        await api.put(`/servicos/${params.id}`, createServiceRequest)
        navigate('/servicos', {
          state: { edited: true }
        })
      } else {
        await api.post("/servicos", createServiceRequest);
        navigate('/servicos', {
          state: { inserted: true }
        })
      }

    } catch (e) {
      console.error(e)
    }
  }

  const handleSelectItem = (event) => {
    setSelectedItem(event.target.value);
    setValue('serviceCategory', event.target.value);
  };

  useEffect(() => {
    if (params?.id) {
      (async () => {
        const response = await findServiceType(parseInt(params.id))
        setValue('name', response.data.nome);
        setValue('serviceValue', `R$ ${response.data.valorBase
          .toFixed(2)
          .replace('.', ',')}`);
        setValue('description', response.data.descricao)
      })()
    }
  }, [])

  return (
    <S.FullPage>
      <BackButton onClick={() => navigate(`/servicos`)}>Voltar</BackButton>
      <S.TitlePage>Adicionar Serviços</S.TitlePage>
      <Card elevation={3}>
        <S.ContainerFieldset>
          <S.InputRow rowsSize={[0.96, 2]}>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ComplementInput label="Nome" required error={error?.message}>
                  <TextField onChange={(e: any) => onChange(e.target.value)} value={value} />
                </ComplementInput>
              )}
            />
          </S.InputRow>
          <S.InputRow rowsSize={[1, 1, 1]}>
            <Controller
              control={control}
              name="serviceValue"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <ComplementInput label="Valor unidade" required error={error?.message}>
                  <InputMoney
                    value={value}
                    onChange={onChange}
                  />
                </ComplementInput>
              )}
            />
          </S.InputRow>
          <S.InputRow rowsSize={[2, 1]}>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ComplementInput label="Descrição" required error={error?.message}>
                  <TextField onChange={(e: any) => onChange(e.target.value)} value={value} />
                </ComplementInput>
              )}
            />
          </S.InputRow>
          <Button
            sx={{ width: 260, height: 46, mt: 1 }}
            onClick={handleSubmit(handleCreateService)}
            variant="contained"
          >
            Salvar
          </Button>
        </S.ContainerFieldset>
      </Card>
    </S.FullPage>
  );
}