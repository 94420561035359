import { createGlobalStyle } from 'styled-components'
import { colors } from './utils/colors'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
  }

  html,body {
    width: 100%;
    min-height: 100%;
    background-color: ${colors.primary}
  }
`
