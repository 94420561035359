import * as S from './styles'

import LogoutIcon from '@mui/icons-material/Logout'
import { MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import logo from '../../../assets/logo-all-white.png'
import { isAdmin, logout } from '../../../services/AuthService'

function Sidebar() {
  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate('/login')
  }

  let list = [
    { name: 'Clientes', onClick: () => navigate('/clientes') },    
    { name: 'Orçamentos', onClick: () => navigate('/budgets') },
    { name: 'Board (Orçamentos)', onClick: () => navigate('/budgets/kanban') },
    { name: 'Serviços', onClick: () => navigate('/servicos') },
  ]
  if(isAdmin()) {
    list = [...list, 
        {name: 'Usuários', onClick: () => navigate('/users')}]
  }

  return (
    <S.ContainerFullSidebar>
      <S.ContainerTextList>
        <S.LogoImg src={logo} />
        <S.ContainerFullList>
          {list.map(item => (
            <MenuItem sx={{ pl: '11%', color: '#fff' }} onClick={item.onClick}>
              {item.name}
            </MenuItem>
          ))}
        </S.ContainerFullList>
      </S.ContainerTextList>
      <MenuItem sx={{ pl: '11%', gap: '10px', color: '#fff' }}  onClick={() => handleLogout()}>
        <LogoutIcon sx={{ color: 'white' }} />
        Sair
      </MenuItem>
    </S.ContainerFullSidebar>
  )
}

export default Sidebar
