import styled from 'styled-components'
import { colors } from '../../../utils/colors'

export const FullPage = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px 3%;
  background-color: ${colors.primary};
`

export const TitlePage = styled.p`
  font-size: 44px;
  font-weight: 700;
  color: ${colors.main};
`

export const ContainerFieldset = styled.div`
  padding: 20px 16px;
  width: 100%;
`

export const InputRow = styled.div<{ rowsSize: number[] }>`
  display: grid;
  grid-template-columns: ${({ rowsSize }) => rowsSize.map(i => `${i}fr`).join(' ')};
  gap: 36px;
  @media screen and (max-width: 1099px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`