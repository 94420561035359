import styled from 'styled-components'

export const ContainerLayout = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ChildrenContainer = styled.div`
  padding-left: 280px;
  width: 100%;
`
