import React from 'react'
import * as S from './styles'

interface IComplementInput {
  children?: any
  label?: string
  required?: boolean
  error?: string
}

function ComplementInput({ children, label, required, error }: IComplementInput) {
  return (
    <S.ContainerComplementInput>
      <S.Label>
        {label || <br />}
        {required && <S.RequiredInput>*</S.RequiredInput>}
      </S.Label>

      {children}
      <S.ErrorText>{error || <br />}</S.ErrorText>
    </S.ContainerComplementInput>
  )
}

export default ComplementInput
