import styled from 'styled-components'
import { colors } from '../../../utils/colors'

export const ContainerFullSidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 50px 0;
  width: 280px;
  height: 100vh;
  background-color: ${colors.main};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ContainerTextList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const ContainerFullList = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const LogoImg = styled.img`
  cursor: pointer;
  width: 78%;
  margin-left: 11%;
`
