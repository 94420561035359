import styled from 'styled-components'
import { colors } from '../../utils/colors'

export const Button = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #fff0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: ${colors.second};
  font-size: 18px;
`
