import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import * as S from './styles'
import BackButton from '../../components/BackButton'
import { useLocation, useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination'
import TableFooter from '@mui/material/TableFooter'
import { TablePaginationActions } from '../../components/Table/Pagination'
import { deleteCustomer, fetchCustomers } from '../../services/ClientesService'
import { getGender, getPhone, getType } from '../../utils/mask'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ConfirmationModal from '../../components/DialogModal/ConfirmationModal'
import { enqueueSnackbar } from 'notistack'

export default function Customers() {
  const [showAlert, setShowAlert] = useState(false)
  const [idToDelete, setIdToDelete] = useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [alertSeverity, setAlertSeverity] = useState('success')
  const [rows, setRows] = useState([])
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)

  const params = useLocation()
  const navigate = useNavigate()

  //Pagination
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [totalRows, setTotalRows] = useState(0)

  const navigateToEdit = (id: number) => {
    navigate(`/clientes/edit/${id}`)
  }

  const navigateToInformation = (id: number) => {
    navigate(`/clientes/info/${id}`)
  }

  const navigateNewCustomer = () => {
    navigate(`/clientes/new`)
  }

  const handleCustomerExclusion = async () => {
    await deleteCustomer(idToDelete)
    setIsOpenConfirmation(false)
    updateTable()
  }
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const updateTable = async () => {
    const response = await fetchCustomers(page, rowsPerPage)
    setRows(response.data.content)
    setTotalRows(response.data.totalElements)
  }

  useEffect(() => {
    if (params.state?.inserted) {
      enqueueSnackbar('Cliente inserido com sucesso', {variant: "success"})
    }
    if (params.state?.edited) {
      enqueueSnackbar('Cliente editado com sucesso', {variant: "success"})
    }
  }, [])

  useEffect(() => {
    updateTable()
  }, [page, rowsPerPage])

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setShowAlert(false)
  }

  return (
    <S.FullPage>
      <BackButton>Voltar</BackButton>
      <S.TitlePage>Listagem de clientes</S.TitlePage>

      <Button sx={{ width: 260, height: 46 }} variant="contained" onClick={navigateNewCustomer}>
        Registrar cliente
      </Button>
      <TableContainer component={Card} elevation={3}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '40%' }}>Nome</TableCell>
              <TableCell sx={{ width: '20%' }}>Telefone</TableCell>
              <TableCell sx={{ width: '20%' }}>Empresa</TableCell>
              <TableCell sx={{ width: '20%' }}>Cargo</TableCell>
              <TableCell sx={{ minWidth: '64px', padding: 0 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell sx={{ width: '40%' }}>{row.nome}</TableCell>
                <TableCell sx={{ width: '20%' }}>{getPhone(row.telefone)}</TableCell>
                <TableCell sx={{ width: '20%' }}>{row.empresa}</TableCell>
                <TableCell sx={{ width: '20%' }}>{row.cargo}</TableCell>
                <TableCell sx={{ minWidth: '96px', padding: 0 }}>
                  <IconButton sx={{ padding: '4px' }} onClick={() => navigateToInformation(row.id)}>
                    <PersonOutlineOutlinedIcon />
                  </IconButton>
                  <IconButton sx={{ padding: '4px' }} onClick={() => navigateToEdit(row.id)}>
                    <ModeEditOutlineOutlinedIcon />
                  </IconButton>
                  <IconButton
                    sx={{ padding: '4px' }}
                    onClick={() => {
                      setIdToDelete(row.id)
                      setIsOpenConfirmation(true)
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={5}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ConfirmationModal
        title={'Atenção!'}
        message={'Deseja realmente excluir este usuário?'}
        open={isOpenConfirmation}
        onAgree={handleCustomerExclusion}
        onDisagree={() => setIsOpenConfirmation(false)}
      />
    </S.FullPage>
  )
}
