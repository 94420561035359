import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { logout } from "../services/AuthService";

export const PrivateRoute: React.FC = () => { 

  const logged = localStorage.getItem("token")

  if (!logged) {
    logout()
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

