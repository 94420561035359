import React from 'react'
import Sidebar from './Sidebar'
import * as S from './styles'

function Layout({ children }: { children?: any }) {
  return (
    <S.ContainerLayout>
      <Sidebar />
      <S.ChildrenContainer>{children}</S.ChildrenContainer>
    </S.ContainerLayout>
  )
}

export default Layout
