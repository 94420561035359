import {axiosApi} from "./api";

const expiresAt = 60 * 24;

export async function login(username: string, password: string) {
  const response = await axiosApi.post('auth/authenticate', {
    username,
    password,
  });
  console.log(response)
  if (response.data.access_token) {
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("role", response.data.role);
  }
  return response.data;
}

export function logout() {
  localStorage.removeItem("token")
}

export function isAdmin() {
  return localStorage.getItem("role") == 'ROLE_ADMIN'
}

export function needAdmin() {

  if (localStorage.getItem("role") != 'ROLE_ADMIN') {
    window.location.href = './acessoNegado'
  }
}