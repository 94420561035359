export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function noAccents(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function convertValue(value: any) {
  return +value?.replace('R$ ', '')?.replace(/\./g, '')?.replace(',', '.') || 0
}

export function formatData(data: string, returnHours?: boolean) {
  const date = new Date(data);
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  if (returnHours) {
    options.hour = 'numeric'
    options.minute = 'numeric'
  }
  return date.toLocaleDateString('pt-BR', options);
}

export const getGender = (gender: string) => {
  if(gender === 'MASCULINO')
    return 'Masculino'
  if(gender === 'FEMININO')
    return 'Feminino'
}

export const getCapitalize = (text: string) => {
  if(text.includes('_')) {
    const [first, second] = text.split('_')
    return `${capitalize(first.toLowerCase())} ${capitalize(second.toLowerCase())}`
  }
  return capitalize(text.toLowerCase())
}

export const getType = (type: string) => {
  if(type === 'ADULTO')
    return 'Adulto'
  if(type === 'PEDIATRA')
    return 'Pediatra'
}

export const getPhone = (phone) => {
  return phone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')
}

export const getZipCode = (zipcode) => {
  return zipcode.replace(/^(\d{5})(\d{3}).*/, '$1-$2')
}