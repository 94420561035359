import { Alert, Slide, Snackbar } from '@mui/material'
import React, { createContext, useCallback, useContext, useState } from 'react'

interface ToastMessage {
  open: boolean
  type: 'success' | 'error'
  message: string
}

interface ToastContextData {
  toast(data: Omit<ToastMessage, 'open'>): void
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [alert, setAlert] = useState<ToastMessage>({
    open: false,
    type: 'success',
    message: ''
  })

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />
  }

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setAlert({ ...alert, open: false })
  }

  function toast({ message, type }: Omit<ToastMessage, 'open'>) {
    setAlert({ message, type, open: true })
  }

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <Snackbar
        open={alert?.open}
        autoHideDuration={5000}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleCloseAlert}
      >
        <Alert variant="filled" severity={alert?.type} sx={{ width: '100%' }}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  )
}

function useToast(): ToastContextData {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('uso incorreto')
  }
  return context
}

export { ToastProvider, useToast }
