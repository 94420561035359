import api from './api'

export async function AllServiceType() {
  const response = await api.get('/servicos')
  return response?.data
}

export async function fetchServiceTypes(page, rowsPerPage) {
  try {
    return await api.get(`/servicos?page=${page}&size=${rowsPerPage}`);
  }catch (error){
    console.error(error);
  }
}

export async function deleteServiceType(id: number){
  try{
    return await api.delete(`/servicos/${id}`)
  }catch (error){
    console.error(error)
  }
}

export async function findServiceType(id: number) {
  try {
    return await api.get(`/servicos/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function fetchCategory() {
  const response = await api.get('/servicos/all-categorias-servico')
  return response?.data
}