import React, {useEffect, useState} from 'react'
import {Button, Card, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import * as S from './styles'
import BackButton from '../../components/BackButton'
import {useLocation, useNavigate} from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination'
import TableFooter from '@mui/material/TableFooter'
import {TablePaginationActions} from '../../components/Table/Pagination'
import {deleteUser, fetchUsers} from '../../services/UsuariosService'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import ConfirmationModal from '../../components/DialogModal/ConfirmationModal'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import {enqueueSnackbar} from 'notistack';
import {needAdmin} from "../../services/AuthService";


function Users() {

  needAdmin()

  const [idToDelete, setIdToDelete] = useState()
  const [rows, setRows] = useState([])
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)

  const params = useLocation()
  const navigate = useNavigate()

  //Pagination
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [totalRows, setTotalRows] = useState(0)

  const navigateToEdit = (id: number) => {
    navigate(`/users/edit/${id}`)
  }

  const navigateNewUser = () => {
    navigate(`/users/new`)
  }

  const handleUserExclusion = async () => {
    await deleteUser(idToDelete)
    setIsOpenConfirmation(false)
    updateTable()
  }
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const updateTable = async () => {
    const response = await fetchUsers(page, rowsPerPage)
    if (response.data.content <= rowsPerPage * page && page > 0) {
      setPage(page - 1)
    }
    setRows(response.data.content)
    setTotalRows(response.data.totalElements)
  }

  useEffect(() => {
    if (params.state?.inserted) {
      enqueueSnackbar('Usuário inserido com sucesso', {variant: "success"})
    }
    if (params.state?.edited) {
      enqueueSnackbar('Usuário editado com sucesso', {variant: "success"})
    }
  }, [])

  useEffect(() => {
    updateTable()
  }, [page, rowsPerPage])

  return (
    <S.FullPage>
      <BackButton>Voltar</BackButton>
      <S.TitlePage>Listagem usuários</S.TitlePage>

      <Button sx={{width: 260, height: 46}} variant="contained" onClick={navigateNewUser}>
        Registrar usuário
      </Button>
      <TableContainer component={Card} elevation={3}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '40%'}}>Nome</TableCell>
              <TableCell sx={{width: '40%'}}>E-mail</TableCell>
              <TableCell sx={{width: '20%'}}>Admin</TableCell>
              <TableCell sx={{minWidth: '64px', padding: 0}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell sx={{width: '40%'}}>{row.nome}</TableCell>
                <TableCell sx={{width: '40%'}}>{row.email}</TableCell>
                <TableCell sx={{width: '20%'}}>
                  {row.role == 'ROLE_ADMIN' ? <CheckCircleOutlineRoundedIcon/> : <ClearRoundedIcon/>}
                </TableCell>
                <TableCell sx={{minWidth: '64px', padding: 0}}>
                  <IconButton sx={{padding: '4px'}} onClick={() => navigateToEdit(row.id)}>
                    <ModeEditOutlineOutlinedIcon/>
                  </IconButton>
                  <IconButton
                    sx={{padding: '4px'}}
                    onClick={() => {
                      setIdToDelete(row.id)
                      setIsOpenConfirmation(true)
                    }}
                  >
                    <DeleteOutlineIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={5}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ConfirmationModal
        title={'Atenção!'}
        message={'Deseja realmente excluir este usuário?'}
        open={isOpenConfirmation}
        onAgree={handleUserExclusion}
        onDisagree={() => setIsOpenConfirmation(false)}
      />
    </S.FullPage>
  )
}

export default Users
