import { ThemeProvider } from '@mui/material'
import { ptBR } from "@mui/material/locale"
import { createTheme } from '@mui/material/styles'
import { SnackbarProvider } from "notistack"
import { Suspense, lazy } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import { PrivateRoute } from './contexts/PrivateRoute'
import { ToastProvider } from './contexts/Toast'
import Customers from './pages/customers'
import ServiceType from './pages/serviceTypes'
import FormServiceType from './pages/serviceTypes/FormServices'
import Users from './pages/users'
import { colors } from './utils/colors'


const NotFound = lazy(() => import('./pages/not-found'))
const FormBudget = lazy(() => import('./pages/budgets/formBudget'))
const FormCustomer = lazy(() => import('./pages/customers/FormCustomers'))
const InformationCustomer = lazy(() => import('./pages/customers/InformationCustomers'))
const LoginPage = lazy(() => import('./pages/login'))
const FormUser = lazy(() => import('./pages/users/FormUser'))
const Budgets = lazy(() => import('./pages/budgets'))
const Kanban = lazy(() => import('./pages/kanban'))

const theme = createTheme({
  palette: {
    primary: {
      main: colors.main
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: colors.third,
          color: colors.second
        },
        body: {
          paddingTop: '10px',
          paddingBottom: '10px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: `${colors.second} !important`
        },
        notchedOutline: {
          borderColor: `${colors.third} !important`
        }
      }
    }
  }
}, ptBR)

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={4} anchorOrigin={{horizontal: "right", vertical: "top"}} />
        <ToastProvider>
          <Suspense fallback={<h1>loading...</h1>}>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Navigate to="/clientes" replace />} />
                <Route
                  path="/budgets"
                  element={
                    <Layout>
                      <Budgets />
                    </Layout>
                  }
                />
                <Route
                  path="/budgets/kanban"
                  element={
                    <Layout>
                      <Kanban />
                    </Layout>
                  }
                />
                <Route
                  path="/budgets/new"
                  element={
                    <Layout>
                      <FormBudget />
                    </Layout>
                  }
                />
                <Route
                  path="/budgets/edit/:id"
                  element={
                    <Layout>
                      <FormBudget />
                    </Layout>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <Layout>
                      <Users />
                    </Layout>
                  }
                />
                <Route
                  path="/users/new"
                  element={
                    <Layout>
                      <FormUser />
                    </Layout>
                  }
                />
                <Route
                  path="/users/edit/:id"
                  element={
                    <Layout>
                      <FormUser />
                    </Layout>
                  }
                />
                <Route
                  path="/clientes"
                  element={
                    <Layout>
                      <Customers />
                    </Layout>
                  }
                />
                <Route
                  path="/clientes/new"
                  element={
                    <Layout>
                      <FormCustomer />
                    </Layout>
                  }
                />
                <Route
                  path="/clientes/edit/:id"
                  element={
                    <Layout>
                      <FormCustomer />
                    </Layout>
                  }
                />
                <Route
                  path="/clientes/info/:id"
                  element={
                    <Layout>
                      <InformationCustomer />
                    </Layout>
                  }
                />
                <Route
                  path="/servicos"
                  element={
                    <Layout>
                      <ServiceType />
                    </Layout>
                  }
                />
                <Route
                  path="/servicos/new"
                  element={
                    <Layout>
                      <FormServiceType />
                    </Layout>
                  }
                />
                <Route
                  path="/servicos/edit/:id"
                  element={
                    <Layout>
                      <FormServiceType />
                    </Layout>
                  }
                />
              </Route>
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </ToastProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
